.social-menu-list {
    display: flex;

    &__item {
        font-size: 3rem;

        i.fab {
            color: white;
        }

        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }
}
