.lang-list {
    >a {
        &+a {
            margin-left: 1rem;
        }
    }

    .button {
        color: black;
        border: none;
        margin-top: 0;
    }

    .button--selected {
        color: var(--color-primary);
    }
}
