$color-secondary: #0060AE;

html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Optum Sans', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #12203E;
  font-size: 1.5rem;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.button {
  display: inline-block;
  color: #FFFFFF;
  padding: 12px 24px;
  margin: 0 auto;
  text-align: center;
  border-radius: 50px;
  margin-top: 0.5rem;
  border: none;
  cursor: pointer;

  background: white;
  border: 1px solid $color-secondary;
  border-radius: 100px;
  color: $color-secondary;
  font-size: 1.5rem;
  font-weight: 700;
  transition: all 100ms;

  &--sm {
    padding: 8px 16px;
    font-size: 1.25rem;
  }

  &--solid {
    color: white;
    background-color: $color-secondary;
  }
}

.button:hover {
  background-color: $color-secondary;
  color: white;
}

h1, h2, h3, h4 {
  color: $color-secondary;
}

.flex-fill {
  flex: 1;
}

@media(min-width: 768px) {
  h1 {
    font-size: 5.3rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 2rem;
  }

  p, li {
    font-size: 1.6rem;
    margin-top: 0;
  }

  .button {
    font-size: 1.75rem;

    &--sm {
      padding: 8px 16px;
      font-size: 1.5rem;
    }
  }
}

@media(max-width: 768px) {
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  p, li {
    font-size: 1.4rem;
  }

  body.voice-tool-started {
    overflow: hidden;
  }

  .mobile-visible {
    display: block;
  }
}
